import React from "react"
import styled from 'styled-components'

export const P = styled.p`
    font-size: 1.5rem;
    margin-bottom: 1.5em;
    line-height: 1.4;
`


export const Small = styled.p`
    font-size: 1rem;
    margin-bottom: 1.5em;
    line-height: 1.4;
`

export const H2 = styled.h2`
    font-size: 2.1rem;
    margin-bottom: 1.5em;
    font-weight: ${p => p.fontWeight || 'bold'};
    letter-spacing: 2px;
    text-transform: ${p => p.uppercase ? 'uppercase' :''};
`

export const H3 = styled.h3`
    font-size: 1.8rem;
    margin-bottom: 1em;
    font-weight: ${p => p.fontWeight || 'bold'};
    letter-spacing: 2px;
    text-transform: ${p => p.uppercase ? 'uppercase' :''}
`

export const H4 = styled.h4`
    font-size: 1.4rem;
    margin-bottom: 1em;
    font-weight: ${p => p.fontWeight || 'bold'};
    letter-spacing: 2px;
    text-transform: ${p => p.uppercase ? 'uppercase' :''}
`