import React, {useState, useEffect, useRef} from "react"
import styled, {css} from 'styled-components'
import PropTypes from "prop-types"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import { Parallax, Background } from 'react-parallax';

import { colors } from '../helper';

const HEADER_HEIGHT = 80;

const SectionStyled = styled.section`
    
    background-color: ${p => p.img ?'': p.colour.bakground };
   
    position: relative;
    min-height: calc((100vw * 0.7));

    @media (min-width: 768px){
        display: flex;
        min-height: ${p => p.fullHeight ? `calc(100vh - ${HEADER_HEIGHT}px)` : 0};
    }
`

const SectionContent = styled.div`
    text-align: ${p => p.align};
    z-index: 100;
    color: ${p => p.colour.text};
    background-color: ${p => p.colour.bakground};
    
    @media (min-width: 768px){
        width: 66.66%;
    }

    div{
        
        padding: 2rem 1rem;

        @media (min-width: 768px){
            padding: 4rem;
        }

        @media (min-width: 960px){
            max-width: calc((960px / 3) * 2 - 4rem);
        }
    }

    
`

const SectionImage = styled.div`
    position: relative;
    width: 100%;
    min-height: calc((100vw * 0.7));

    @media (min-width: 768px){
        width: 33.33%  ;
    }

    @media (min-width: 960px){
        width:calc(33.33% + (960px / 3));
    }
`


const absoluteFill = css`
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`

const StyledBackgroundImage = styled(BackgroundImage)`
    ${absoluteFill}
    background-position: 50%;
    background-size: cover;
`

const BackgroundColor = styled.div`
    ${absoluteFill}
    background: ${colors.secondary};
    opacity: .65;
    z-index: 50;
`

export default function SplitSection({colour = 'default', children, full, align, img, ...rest}){

    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [translateY, setTranslateY] = useState(0);

    const backgroudRef = React.createRef();

    console.log(translateY);

    const colours = {
        beige:{
            bakground: colors.primary,
            text: colors.white
        },
        default: {
            bakground: colors.white,
            text: colors.secondary
        }
    }


    const content = (
        <SectionContent colour={colours[colour]}  align={align}>
            <div>
                {children}
            </div>
        </SectionContent>
    )

    return(
        <SectionStyled colour={colours[colour]} ref={backgroudRef} img={img} {...rest}>
            <SectionImage>
                <StyledBackgroundImage Tag="div" backgroundPos={translateY} fluid={img.childImageSharp.fluid} />
            </SectionImage>

            {content}  
        </SectionStyled>
    )
}

SectionStyled.propTypes = {
    colour: PropTypes.string,
}
  
SectionStyled.defaultProps = {
    colour: `default`
}

SectionContent.propTypes = {
    align: PropTypes.string,
}

SectionContent.defaultProps = {
    align: `left`,
}