import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Events from "../components/events"
import Prices from "../components/prices"
import About from "../components/about"
import FullImage from "../components/fullImage"
import Tipis from "../components/tipis"
import Contact from "../components/contact"



const IndexPage = () => {
  const data = useStaticQuery(graphql`
        query {
          social: file(relativePath: { eq: "social.jpg" }) {
            childImageSharp {
              resize(width: 1024) {
                src
                height
                width
              }
            }
          },
          hero: file(relativePath: { eq: "hero_bg.jpg" }) {
            childImageSharp {
                fluid(quality: 100) {
                ...GatsbyImageSharpFluid,
                
                }
            }
          },
          nightImage: file(relativePath: { eq: "night_image.jpg" }) {
              childImageSharp {
                  fluid(quality: 100) {
                  ...GatsbyImageSharpFluid,
                  
                  }
              }
          },
          furnished: file(relativePath: { eq: "furnished.jpg" }) {
            childImageSharp {
                fluid(quality: 100) {
                ...GatsbyImageSharpFluid,
                
                }
            }
        },
        hill: file(relativePath: { eq: "hill.jpg" }) {
          childImageSharp {
              fluid(quality: 100) {
              ...GatsbyImageSharpFluid,
              
              }
          }
      }
        }`);

  return(
    <Layout>
      <SEO title="Tipis of London" image={data.social.childImageSharp.resize} />
      <Hero img={data.hero} />
      <About img={data.furnished}/>
      <FullImage img={data.nightImage} />
      <Tipis />
      <Prices title="Prices"/>
      <Contact img={data.hill}/>
    </Layout>
  )
}

export default IndexPage
