import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"

import {colors} from '../helper'

import Section from './section';
import {P, H2,Small} from './type';


const ButtonLink = styled.a`
    border: 3px solid ${colors.white};
    padding: .5em 3em;
    color: ${colors.white};
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    
    @media (min-width: 768px){
        margin-bottom: 0;
        margin-top: auto;
    }
`

const PricesContainer = styled.div`
    
    width: 100%;
    

    @media(min-width: 768px){
        display: flex;
        justify-content: center;
    }
`;
const PriceColumn = styled.div`
    padding-bottom: 120px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction:  column;
    align-items: center;
    flex: 1 1 0px;
`;
const PriceTitle = styled.h3``;
const Price = styled.h4`
    font-size: 2.5rem;
`;

const PriceDescription = styled.p``;
const PriceFeatures = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 30px;
    width: 100%;
`;
const Feature = styled.li`
    border-bottom: 1px solid rgba(255,255,255,.4);
    padding: 15px;
    margin: 0;

    &:last-child{
        border: 0;
    }
`;



const Prices = ({title}) => {
    return(
    <Section colour="beige" id="prices" align="center">
        <H2>{title}</H2>
        <PricesContainer>
       
            <PriceColumn>
                <PriceTitle>Regular</PriceTitle>
                
                <Price>£149</Price>
                <PriceDescription>Just the tipi</PriceDescription>
                <PriceFeatures>
                    <Feature>Free local devlivery</Feature>
                    <Feature>Tipi assembley</Feature>
                    <Feature>Cotton canvas tipi</Feature>
                    <Feature>Matting</Feature>
                </PriceFeatures>
                <ButtonLink target="_blank" href="https://forms.gle/3x6gN6Yc9iBbRJacA">Book Now</ButtonLink>
            </PriceColumn>
       
            <PriceColumn>
                <PriceTitle>Delux</PriceTitle>
                
                <Price>£199</Price>
                <PriceDescription>Our tipi with all the trimmings</PriceDescription>
                <PriceFeatures>
                    <Feature>Cotton canvas tipi and matting</Feature>
                    <Feature>Beanbags</Feature>
                    <Feature>Sheepskin Rugs</Feature>
                    <Feature>Moroccan Tables</Feature>
                    <Feature>Lanterns and Lighting</Feature>
                    <Feature>Heating</Feature>
                </PriceFeatures>
                <ButtonLink target="_blank" href="https://forms.gle/3x6gN6Yc9iBbRJacA">Book Now</ButtonLink>
            </PriceColumn>
        </PricesContainer>
        <Small>For bespoke solutions please get in touch via email, we'd love to make your ideas a reality!</Small>
    </Section>)
}

export default Prices;

