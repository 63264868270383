import React from "react"
import styled from 'styled-components'
import {P, H2, H3, Small} from './type';
import Facebook from './svgs/facebook';
import Twitter from './svgs/twitter';
import Insta from './svgs/instagram';

import Section from './section';

import Mailchimp from './mailchimp'

const ContactSection = styled.div`
    padding-bottom: 3rem;
`

const StyledLink = styled.a`
    color: #fff;
    text-decoration: none;
`

const SocialLink = styled.a`
    padding: 0 .5rem;
`


export default function Contact({img}){

    const mailchimpUrl = `https://tipisoflondon.us17.list-manage.com/subscribe/post?u=43d73ef957d42259bff1cdfd9&amp;id=58d428c770`;

    return(
        <Section align="center" colour="beige" id="contact" img={img}>
            <H2>Contact</H2>
            <ContactSection>
                <H3>Email</H3>
                <StyledLink href="mailto:info@tipisoflondon.co.uk">info@tipisoflondon.co.uk</StyledLink>
            </ContactSection>
            <ContactSection>
                <H3>Social</H3>
                <SocialLink href="https://www.instagram.com/tipisoflondon/" target="_blank">
                    <Insta />
                </SocialLink>
                {/* <SocialLink href="https://www.facebook.com/benandthebadweather" target="_blank">
                    <Facebook />
                </SocialLink>
                <SocialLink href="https://twitter.com/ndthbdwthr" target="_blank">
                    <Twitter />
                </SocialLink> */}
            </ContactSection>
            <ContactSection>
                <H3>Mailing List</H3>
                <Small>Sign up to our mailing list to recive updates on offers and what we're up to!</Small>
                <Mailchimp url={mailchimpUrl} />
            </ContactSection>
        </Section>
    )
}