import React from "react"
import styled from 'styled-components'
import {P, H2, H3, H4, Small} from './type';

import SplitSection from './split-section';

export default function About({img}){
    return(
        <SplitSection id="about" colour="beige" {...{img}}>
            <H2 fontWeight="normal">Welcome to Tipis of London</H2>
            <H3 fontWeight="normal">Tipis of London provide large cotton canvas tipi tents and furnishings for hire in and around the London area.</H3>

            <P>Whether you desire a party chillout area, a romantic getaway or extra accommodation for guests, Tipis of London offer a range of furnishings and themes to suit your requirements.</P>

            <H4 fontWeight="normal">Any Occasion</H4>
            <Small>From parties and weddings to camping and accommodation, our tipis can be used for a wide range of events and purposes. </Small>

            <H4 fontWeight="normal">Themes and Interiors</H4>
            <Small>We take great care to source lighting, furniture and soft furnishings that we feel complement the tipi aesthetic. Check out our Moroccan and Natural themes.</Small>

            <H4 fontWeight="normal">Great Prices</H4>
            <Small>Starting at £120, we offer a range of competitive prices reflecting your requirements, as well as free delivery to London, Hertfordshire, Bedfordshire, Buckinghamshire and Northamptonshire.</Small>

        </SplitSection>
    )
}