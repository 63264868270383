import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled, {css} from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Logo from './svgs/big_logo.js';

import BackgroundImage from 'gatsby-background-image'
import {colors} from '../helper'
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const HEADER_HEIGHT = 80;

const absoluteFill = css`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; // or whatever
`

const HeroStyled = styled.div`
    position: relative;
    height: calc(100vh - ${HEADER_HEIGHT}px);
    overflow-y: hidden;
    overflow-x: hidden;
`

const HeroContent = styled.div`
    ${absoluteFill}
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100;
    padding: 2rem;
`

const LogoContainer = styled.div`    
    max-width: 400px;
    
    display:block;

    svg{
        width: 100%;
        height: auto;
        margin-bottom: 2em;
    }
`
const BackgroundColor = styled.div`
    ${absoluteFill}
    background: ${colors.secondary};
    opacity: .65;
    z-index: 50;
`


const StyledBackgroundImage = styled(BackgroundImage)`
    ${absoluteFill}
    background-position: 50% 80px  ;
    background-attachment: fixed;
    background-size: cover;    
`

const BookButton = styled(ScrollLink)`
    border: 3px solid #ffffff;
    padding: .5em 3em;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    background: transparent;
    display: block;
    width: 100%;
    cursor: pointer;

    @media (min-width: 768px){
        width: auto;
        
    }
`

const Hero = ({img}) => {
    

    return(
    <HeroStyled>
        <HeroContent>
        
            <LogoContainer>
                <Logo />
            </LogoContainer>
            <BookButton to="prices" smooth="true" offset={-80}>Book Now</BookButton>
        </HeroContent>

        <StyledBackgroundImage Tag="div" fluid={img.childImageSharp.fluid} />
        <BackgroundColor />
    </HeroStyled>)
}

export default Hero;

