import React from "react"
import styled from 'styled-components'
import {P, H2, H4, Small} from './type';

import Section from './section';

export default function Tipis({img,}){
    return(
        <Section id="the-tipis" align="center" {...{img}}>
            <H2>The Tipis</H2>
            <P>Our modern cotton canvas tipis are modelled on the timeless design of the Sami lavvu and the classic Native American tipi. At 5m in diameter and 3.5m tall, our tipis can sleep up to eight people and can comfortably seat twelve. The interior furnishings we offer combine vintage and modern design features.</P>
            
            <P>On its own or in a cluster, the classic tipi shape creates a unique and striking silhouette, while the neutral tones of its unbleached canvas harmonise with the beauty of the natural environment.</P>

            <H4>Furnishings</H4>
            <Small>Depending on your event, we can equip your tipi with hard and soft furnishings, whether beds to sleep in, cushions to lounge on or tables for you to rest your drink on. All our lighting and furniture is carefully chosen with your comfort and your eye for harmonious design in mind.</Small>
            <H4>Comfort</H4><Small>We want you to feel completely at home in our tipis. That’s why we offer a range of comfortable furnishings, from cushions and beanbags to rugs and sheepskins.</Small>
            <H4>Atmosphere</H4><Small>Our heating options keep you cozy and sheltered in warmth on cooler nights and comfortable in the sweltering summer, while our lighting options – flickering candles and twinkling fairy lights – bathe the interior in a warm glow.</Small>
        </Section>
    )
}