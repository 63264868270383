import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="875"
      height="539"
      viewBox="0 0 875 539"
    >
      <g data-name="Group 16" transform="translate(-520 -978)">
        <g data-name="Group 11" transform="translate(-126 388.846)">
          <g fill="none" data-name="Polygon 3">
            <path
              d="M211 0l211 366H0z"
              transform="translate(872 589.154)"
            ></path>
            <path
              fill="#fff"
              d="M211 40.044L34.616 346h352.768L211 40.044M211 0l211 366H0L211 0z"
              transform="translate(872 589.154)"
            ></path>
          </g>
          <path
            fill="#fff"
            stroke="#fff"
            strokeWidth="20"
            d="M1014.898 949.361l128.943-228.324"
            data-name="Path 2"
          ></path>
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="20"
            d="M0 0L82.107 0"
            data-name="Line 5"
            transform="translate(1041.762 676.655)"
          ></path>
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="20"
            d="M0 0L66.573 115.394"
            data-name="Line 6"
            transform="translate(1083.925 834.212)"
          ></path>
        </g>
        <text
          fill="#fff"
          data-name="Tipis Of London"
          fontFamily="Josefin Sans"
          fontSize="100"
          letterSpacing=".1em"
          transform="translate(520 1492)"
        >
          <tspan x="0" y="0">
            Tipis Of London
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default Icon;
