import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"

import Section from './section';
import {P, H2} from './type';



const EventsStyled = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const EventStyled = styled.li`
    padding: 1.5em 0;
    
    
    border-bottom: 1px solid #9A9393;
    margin-bottom: 0;
    text-align: left;

    &:last-child{
        border-bottom: 0;
    }

    @media (min-width: 768px){
        display: flex;
        align-items: center;
    }
`

const EventDate = styled.span`
    font-size: 2.1em;
    padding-right: 30px;
    color: #9A9393;
    display: block;
    line-height: 1;
    margin-top: -.1em;
    margin-bottom: 1rem;

    @media (min-width: 768px){
        margin-bottom: 0;
    }

    @media (min-width: 960px){
        font-size: 3.1em;
    }
`

const EventInfo = styled.div`
    margin-bottom: 1rem;

    h3{
        margin: 0;
        font-size: 1.2rem;
        font-weight: bold;
    }

    @media (min-width: 768px){
        margin-bottom: 0;
    }
`

const ButtonLink = styled.a`
    border: 3px solid #9A9393;
    padding: .5em 3em;
    color: #9A9393;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    
    @media (min-width: 768px){
        margin-right: 0;
        margin-left: auto;
    }
`

const Event = ({venue, location, time, date}) => (
    <EventStyled>
        <EventDate>{date}</EventDate>
        <EventInfo>
        <h3>{venue} - {time}</h3>
        <p>{location}</p>
        </EventInfo>
        <ButtonLink href="#">
            Event
        </ButtonLink>
        
    </EventStyled>
);


const Events = ({title}) => {
    return(
    <Section colour="beige" id="events" align="center">
        <H2>{title}</H2>
        <EventsStyled>
        {/* <Event date="27.07.2022" time="21:15" location="London" venue="The Royal Albert Hall" />
        <Event date="29.07.2022" time="21:15" location="New York" venue="Madison Square Garden" />
        <Event date="31.07.2022" time="21:15" location="Los Angeles" venue="The LA Forum" /> */}
        <P>There are currently no events scheduled</P>
        </EventsStyled>
    </Section>)
}

export default Events;

Event.propTypes = {
    date: PropTypes.string,
    time: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
}
  
Event.defaultProps = {
    title: ``
}